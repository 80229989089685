<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="regDisclBack" :style="main_cont_style">
        <div class="modalRegisDiscla">
          <div>
            <div class="separador_h_mobile"></div>
            <headerCerrar @cancelar="cerrarModal" colorIcon="rojoOscuro" />
          </div>

          <div class="regisDisclaTitle title">
            {{ t("app.registroDisclaimer.tit") }}
          </div>

          <div class="regisDisclTextSup">
            {{ remitente.cliente_nuevo == "0" ? t("app.registroDisclaimer.texto_v2") : t("app.registroDisclaimer.texto_v3") }}
          </div>

          <!-- terminos y condiciones -->
          <div class="regisAcepto textUnderLine">
            <checkBoxAzul
              @changeStateChek="changeStateChekLocal_term_cond"
              :error="checkBoxError_term_cond"
              :initial_state="accept_term_cond"
            />
            <div @click="callTermsAndCond">
              {{ t("app.registroDisclaimer.acepto_term_cond") }}
            </div>
          </div>

          <!-- politica de privacidad -->
          <div class="regisAcepto textUnderLine">
            <checkBoxAzul
              @changeStateChek="changeStateChekLocal_pol_priv"
              :error="checkBoxError_pol_priv"
              :initial_state="accept_poli_priv"
            />
            <div @click="callPoliticasPrivacidad">
              {{ t("app.registroDisclaimer.acepto_pol_privacidad") }}
            </div>
          </div>

          <!-- consulta registro civil -->
          <div class="regisAcepto textUnderLine">
            <checkBoxAzul
              @changeStateChek="changeStateChekLocal_reg_civil"
              :error="checkBoxError_reg_civil"
              :initial_state="accept_consulta_reg_civil"
            />
            <div @click="call_consulta_reg_civil">
              {{ t("app.registroDisclaimer.acepto_consulta_reg_civil") }}
            </div>
          </div>
          <hr class="regisDisclHr" />

          <div class="regisDiscBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.continuar')"
              @action="registrar"
              :disabled="false"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="msg_completar"
            />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { alertController, modalController } from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import checkBoxAzul from "../components/remitente/checkBoxAzul.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import faltanDatosModal from "@/views/modales/faltanDatos.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registroDisclaimer",
  components: {
    headerCerrar,
    btnAction,
    checkBoxAzul,
    screen_width_cont,
  },
  data() {
    return {
      subTitle: "",
      checkBoxError_term_cond: false,
      checkBoxError_pol_priv: false,
      checkBoxError_reg_civil: false,
      en_vista: false,
    };
  },
  computed: {
    ...mapState([
      "accept_term_cond",
      "accept_poli_priv",
      "accept_consulta_reg_civil",
      "widthScreen",
      "heigthScreen",
      "plataforma",
      "configuracion",
      "faltanDatos",
      "remitente",
      "resp_validar_codigo_registro",
      "idiomaPreferencia",
      "resp_floid_verifica_rut",
      "url_base_web",
    ]),

    estadoBtn() {
      let aux = "inactive";
      if (
        this.accept_term_cond &&
        this.accept_poli_priv &&
        this.accept_consulta_reg_civil
      ) {
        aux = "active";
      }
      return aux;
    },
    main_cont_style() {
      let style = {
        height: "100vh",
      };
      if (this.plataforma == "web") {
        style = {
          height: `${this.heigthScreen - 4 * 16}px`,
        };
      }
      return style;
    },
    msg_completar() {
      let aux = "";
      if (!this.accept_term_cond) {
        aux = this.t("app.registroDisclaimer.error_term_cond");
        return aux;
      } else if (!this.accept_poli_priv) {
        aux = this.t("app.registroDisclaimer.error_politicas_priv");
        return aux;
      } else if (!this.accept_consulta_reg_civil) {
        aux = this.t("app.registroDisclaimer.error_acept_registro_civil");
        return aux;
      } else {
        return aux;
      }
    },
    queda_tiempo_completar_registro(){
      let aux = true;
      if(this.remitente.estado_registro == "2"){
        aux = false;
      }
      if(this.remitente.estado_registro == "3" && true){//implementar fecha límite de completar registro para procesos futuros de actualización de datos
        aux = false;
      }
      
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateDesde",
      "updateBloquearNavegacion",
      "setshowSpinner",
      "set_accept_term_cond",
      "set_accept_poli_priv",
      "set_accept_consulta_reg_civil",
    ]),
    ...mapActions([
      "signOff",
      "traer_ocupaciones",
      "traer_regiones",
      "callFloidVerificaRut",
    ]),

    changeStateChekLocal_term_cond(estado) {
      // this.acepto_term_cond = estado;
      this.set_accept_term_cond(estado);
      this.checkBoxError_term_cond = false;
    },
    changeStateChekLocal_pol_priv(estado) {
      // this.acepto_pol_priv = estado;
      this.set_accept_poli_priv(estado);
      this.checkBoxError_pol_priv = false;
    },
    changeStateChekLocal_reg_civil(estado) {
      // this.acepto_reg_civil = estado;
      this.set_accept_consulta_reg_civil(estado);
      this.checkBoxError_reg_civil = false;
    },
    cerrarModal() {
      console.log(this.plataforma);

      if (this.plataforma == "web") {
        window.location.replace(
          `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
        );
      } else {
        this.$router.push({ name: "logIn", replace: true });
      }
      this.signOff();
    },
    async presentAlert() {
      let alert = await alertController.create({
        subHeader: this.subTitle,
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "regisDisclAlertBtn",
          },
        ],
        cssClass: "regisDisclAlert",
      });
      await alert.present();
    },
    registrar() {
      // if (
      //   this.faltanDatos == true &&
      //   this.remitente.cliente_nuevo == "0" &&
      //   this.remitente.estado_registro == "2"
      // ) {
      //   this.callFloidVerificaRut(this.remitente.numero_documento);
      // } else {
      //   this.setshowSpinner(false);
      //   this.$router.push({ name: "registro", replace: true });
      // }
      if (
        this.faltanDatos == true &&
        this.remitente.cliente_nuevo == "0" &&
        this.remitente.estado_registro == "2"
      ) {
        this.activarModalFaltanDatos();
      } else if (
        this.faltanDatos == true &&
        this.remitente.cliente_nuevo == "1" &&
        this.remitente.estado_registro == "2"
      ){
        this.traer_ocupaciones();
        this.traer_regiones("152");
        this.callFloidVerificaRut(this.remitente.numero_documento);
      } else {
        this.traer_ocupaciones();
        this.traer_regiones("152");
        this.callFloidVerificaRut(this.remitente.numero_documento);
      }
    },

    async activarModalFaltanDatos() {
      let cssClass = ["myModalAbandonar"];
      if (this.widthScreen < 400) {
        cssClass.push("myModalAbandonar_ancho_90");
      } else if (this.widthScreen < 600) {
        cssClass.push("myModalAbandonar_ancho_80");
      } else if (this.widthScreen < 800) {
        cssClass.push("myModalAbandonar_ancho_70");
      } else {
        cssClass.push("myModalAbandonar_ancho_50");
      }

      if (this.heigthScreen < 600) {
        cssClass.push("myModalAbandonar_alto_90");
      } else if (this.heigthScreen < 700) {
        cssClass.push("myModalAbandonar_alto_80");
      } else if (this.heigthScreen < 900) {
        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_alto_70");
        } else {
          cssClass.push("myModalAbandonar_alto_50");
        }
      } else if (this.heigthScreen < 1200) {
        cssClass.push("myModalAbandonar_alto_60");
      } else {
        cssClass.push("myModalAbandonar_alto_60");
      }

      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: faltanDatosModal,
        animated: true,
        backdropDismiss: false,
        cssClass: cssClass,
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);

        if (data.data.completarRegistro) {
          if (
            this.faltanDatos == true &&
            this.remitente.cliente_nuevo == "0" &&
            this.remitente.estado_registro == "2"
          ) {
            this.setshowSpinner(true);
            this.traer_ocupaciones();
            this.traer_regiones("152");
            this.callFloidVerificaRut(this.remitente.numero_documento);
          }

          
        } else {
          if (!this.queda_tiempo_completar_registro) {
            this.signOff();
          } else {
            this.$router.push({ name: "home", replace: true });
          }
        }
      });

      return modal.present();
    },

    async callTermsAndCond() {
      this.$router.push({
        name: "term_condi",
        replace: true,
      });
    },
    async callPoliticasPrivacidad() {
      this.$router.push({
        name: "politicas_privacidad",
        replace: true,
      });
    },
    async call_consulta_reg_civil() {
      this.$router.push({
        name: "consulta_reg_civil",
        replace: true,
      });
    },
  },
  watch: {
    resp_floid_verifica_rut() {
      if (this.resp_floid_verifica_rut.status && this.en_vista) {
        this.setshowSpinner(false);
        if (this.resp_floid_verifica_rut.resp) {
          this.$router.push({ name: "registroFormInicial", replace: true });
          // if(this.remitente.cliente_nuevo == "0" &&
          //   this.remitente.estado_registro == "2"){
          //   this.$router.push({ name: "registroFormInicial", replace: true });
          // }else{
          //   this.$router.push({ name: "registroFormEdit", replace: true });
          // }
          
        }
      }
    },
  },
  ionViewDidEnter() {
    this.updateDesde("registroDisclaimer");
    // this.acepto_term_cond = this.accept_term_cond;
    // this.acepto_pol_priv = this.accept_poli_priv;
    // this.acepto_reg_civil = this.accept_consulta_reg_civil;
    this.en_vista = true;
  },
  ionViewWillLeave() {
    this.en_vista = false;
  },
};
</script>

<style>
.regDisclBack {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
}
.modalRegisDiscla {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  padding: 1.6875rem 1.5625rem 2.125rem 1.5625rem;
}
.regisDisclaTitle {
  color: var(--customA-801B1C);
  text-align: center;
  margin-top: 1rem;
}
.regisDisclTextSup {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--customG-787878);
  text-align: justify;
  padding-bottom: 1rem;
}
.regisAcepto {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125;
  display: flex;
  align-items: flex-end;

  color: var(--customC-614AD3);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.regisAcepto div {
  padding-left: 1rem;
}

.regisDisclHr {
  border: 1px solid rgba(196, 196, 196, 0.3);
  margin-top: 1.5rem;
}
.regisDisclTextInf {
  margin-top: 1.5rem;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--customH-4C4C4C);
  text-align: center;
}
.regisDisclTextInf span {
  text-decoration: underline;
}
.regisDiscBtn {
  margin-top: 2rem;
}
.regisDisclAlertBtn {
  font-size: 0.5rem;
  font-weight: 500;
  line-height: 9.38px;
  color: var(--customH-4C4C4C) !important;
}
.regisDisclAlert .alert-sub-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 18.38px;
  color: var(--customC-614AD3) !important;
}

@media (min-height: 750px) {
  .regisAcepto {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .regisDiscBtn {
    margin-top: 3rem;
  }
  .regisDisclTextSup {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }
  .modalRegisDiscla {
    padding: 1.6875rem 1.5625rem 3.125rem 1.5625rem;
  }
}
</style>