<template>
  <div class="text-left" :class="error ? 'checkBoxAconError' : 'checkBoxAsinError'">
    <label class="containerA "
      >
      <input type="checkbox" v-model="estadoCheck" />
      <span class="checkmarkA " :class="error ? 'checkmarkAconError' : 'checkmarkAsinError'"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "checkBoxRojo",
  props: {
    // label: { type: String },
    error: {type: Boolean},
    initial_state: Boolean,
  },
  data() {
    return {
      estadoCheck: false,
    };
  },
  methods: {},
  watch: {
    estadoCheck() {
      this.$emit("changeStateChek", this.estadoCheck);
    },
    initial_state(){
      this.estadoCheck = this.initial_state;
    }
  },
  mounted(){
    this.estadoCheck = this.initial_state;
  }
};
</script>

<style scoped>
/* The container */
.containerA {
  display: block;
  position: relative;
  padding-left: 25px;
  padding-top: 1px;
  width: fit-content;
  cursor: pointer;

  /* color: var(--customC-614AD3); */
  /* font-size: 0.6875rem;
  font-weight: 500;
  line-height: 13px; */
  /* display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em; */

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerA input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkA {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  /* border: 1.5px solid var(--customC-614AD3); */
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.containerA:hover input ~ .checkmarkA {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.containerA input:checked ~ .checkmarkA {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkA:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerA input:checked ~ .checkmarkA:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerA .checkmarkA:after {
  left: 3.5px;
  top: -0.5px;
  width: 7px;
  height: 12px;
  border: solid var(--customC-614AD3);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>